var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.proRecipe && _vm.isPublishPeriod(_vm.proRecipe)
    ? _c(
        "div",
        { staticClass: "dp-pro-recipe-detail" },
        [
          _vm.isLoading
            ? _c("DpPageLoading", { attrs: { "is-loading": _vm.isLoading } })
            : _vm._e(),
          _c(
            "div",
            { style: { opacity: !_vm.isLoading ? 1 : 0 } },
            [
              _vm.images
                ? _c(
                    "DpProRecipeDetailCarousel",
                    { attrs: { contents: _vm.images } },
                    _vm._l(_vm.images, function (image) {
                      return _c(
                        "div",
                        { key: image.index, staticClass: "swiper-slide" },
                        [
                          _c("DpProRecipeDetailCarouselCard", {
                            attrs: { content: image },
                            on: { "on-img-loaded": _vm.onContentImgLoaded },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c("div", { staticClass: "dp-pro-recipe-detail-overview" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "dp-pro-recipe-detail-overview__myrecipe-button",
                  },
                  [
                    _c("DpMyrecipeButton", {
                      attrs: { id: _vm.proRecipe.id, state: _vm.isBookmarked },
                      on: {
                        "switch-myrecipe-button": _vm.onSwitchMyrecipeButton,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "dp-pro-recipe-detail-overview-head" },
                  [
                    _vm.proRecipe.appeal_word
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "dp-pro-recipe-detail-overview-head__subtitle",
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.proRecipe.appeal_word) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.proRecipe.name
                      ? _c(
                          "h2",
                          {
                            staticClass:
                              "dp-pro-recipe-detail-overview-head__title",
                          },
                          [_vm._v(" " + _vm._s(_vm.proRecipe.name) + " ")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass:
                          "dp-pro-recipe-detail-overview-head__bottom",
                      },
                      [
                        _c(
                          "dl",
                          { staticClass: "dp-pro-recipe-detail-overview-time" },
                          [
                            _vm.proRecipe.required_time
                              ? [
                                  _c(
                                    "dt",
                                    [
                                      _c("Icon", {
                                        attrs: {
                                          name: "timerR",
                                          color: "dpBlack01",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "dp-pro-recipe-detail-overview-time__label",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.WORDS
                                                  .DRIP_POD_PRO_RECIPE_DETAIL
                                                  .REQUIRED_TIME
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("dd", [
                                    _vm._v(_vm._s(_vm.proRecipe.required_time)),
                                  ]),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "dp-pro-recipe-detail-overview-button",
                          },
                          [
                            _c(
                              "DpButton",
                              {
                                attrs: { type: "button", size: "sm" },
                                on: { click: _vm.onClickSetBody },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "iconPrepend",
                                      fn: function () {
                                        return [
                                          _c("Icon", {
                                            attrs: {
                                              name: "dpPlus",
                                              width: "16",
                                              height: "16",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3580591628
                                ),
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "dp-pro-recipe-detail-overview-button__label",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.WORDS.DRIP_POD_PRO_RECIPE_DETAIL
                                          .SET_BODY
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _vm.proRecipe.description
                  ? _c("p", {
                      staticClass: "dp-pro-recipe-detail-overview-description",
                      domProps: {
                        innerHTML: _vm._s(_vm.proRecipe.description),
                      },
                    })
                  : _vm._e(),
              ]),
              _vm.proRecipe.capsule &&
              _vm.isPublishPeriod(_vm.proRecipe.capsule)
                ? _c("div", { staticClass: "dp-pro-recipe-detail-capsule" }, [
                    _c(
                      "h3",
                      { staticClass: "dp-pro-recipe-detail-capsule__title" },
                      [
                        _c("Icon", {
                          attrs: {
                            name: "capsuleR",
                            color: "dpBlack01",
                            width: 15,
                            height: 15,
                          },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.WORDS.DRIP_POD_PRO_RECIPE_DETAIL.USE_CAPSULE
                            ) +
                            " "
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "dp-pro-recipe-detail-capsule-content" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "dp-pro-recipe-detail-capsule-content__thumbnail",
                          },
                          [
                            _c("img", {
                              attrs: { src: _vm.proRecipe.capsule.image_url },
                            }),
                          ]
                        ),
                        _c("div", [
                          _vm.proRecipe.capsule.name
                            ? _c(
                                "h3",
                                {
                                  staticClass:
                                    "dp-pro-recipe-detail-capsule-content__title",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.proRecipe.capsule.name) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.proRecipe.capsule.description
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "dp-pro-recipe-detail-capsule-content__description",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.proRecipe.capsule.description
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "dp-pro-recipe-detail-capsule-content__button",
                            },
                            [
                              _vm.capsuleEcUrl
                                ? _c(
                                    "DpTextButton",
                                    {
                                      attrs: {
                                        href: _vm.capsuleEcUrl,
                                        size: "sm",
                                        align: "left",
                                        fluid: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "iconAppend",
                                            fn: function () {
                                              return [
                                                _c("Icon", {
                                                  attrs: {
                                                    name: "dpArrowRightB",
                                                    color: "dpBlack01",
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3194498299
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.WORDS.DRIP_POD_PRO_RECIPE
                                              .STORE_LINK_CAPSULE
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.proRecipe.recipe && _vm.proRecipe.recipe.length > 0
                ? _c(
                    "DpSection",
                    {
                      attrs: {
                        "title-en": _vm.WORDS.DRIP_POD_PRO_RECIPE.RECIPE,
                      },
                    },
                    [
                      _c(
                        "DpSectionInner",
                        [
                          _c("DpProRecipeMaterialList", {
                            attrs: { contents: _vm.proRecipe.recipe },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.proRecipe.process &&
              _vm.proRecipe.process.length > 0 &&
              _vm.proRecipe.process[0]
                ? _c(
                    "DpSection",
                    {
                      attrs: {
                        "title-en": _vm.WORDS.DRIP_POD_PRO_RECIPE.STEPS,
                      },
                    },
                    [
                      _c(
                        "DpSectionInner",
                        [
                          _c("DpProRecipeStepList", {
                            attrs: { contents: _vm.proRecipe.process },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.proRecipe.capsule &&
              _vm.isPublishPeriod(_vm.proRecipe.capsule)
                ? _c(
                    "DpSection",
                    {
                      attrs: {
                        "title-en": _vm.WORDS.DRIP_POD_PRO_RECIPE.TASTE,
                      },
                    },
                    [
                      _c(
                        "DpSectionInner",
                        [
                          _c("DpProRecipeTaste", {
                            attrs: { contents: _vm.proRecipe },
                          }),
                        ],
                        1
                      ),
                      _vm.proRecipe.barista &&
                      _vm.isPublishPeriod(_vm.proRecipe.barista) &&
                      _vm.proInformation
                        ? _c(
                            "DpSectionInner",
                            [
                              _vm.proRecipe.message
                                ? _c("DpProRecipeBalloon", {
                                    attrs: {
                                      title:
                                        _vm.WORDS.DRIP_POD_PRO_RECIPE.POINT,
                                      text: _vm.proRecipe.message,
                                    },
                                  })
                                : _vm._e(),
                              _c("DpProRecipeProInformation", {
                                attrs: { contents: _vm.proInformation },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "dp-pro-recipe-detail-capsule-drip-button" },
                [
                  _c(
                    "DpButton",
                    {
                      attrs: { size: "lg", "style-type": "primary", fluid: "" },
                      on: { click: _vm.startBrew },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.WORDS.DRIP_POD_PRO_RECIPE_DETAIL.DRIP) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }