<template>
  <div
    v-if="proRecipe && isPublishPeriod(proRecipe)"
    class="dp-pro-recipe-detail">
    <DpPageLoading v-if="isLoading" :is-loading="isLoading" />

    <div :style="{ opacity: !isLoading ? 1 : 0 }">
      <DpProRecipeDetailCarousel v-if="images" :contents="images">
        <div v-for="image in images" :key="image.index" class="swiper-slide">
          <DpProRecipeDetailCarouselCard
            :content="image"
            @on-img-loaded="onContentImgLoaded" />
        </div>
      </DpProRecipeDetailCarousel>

      <div class="dp-pro-recipe-detail-overview">
        <div class="dp-pro-recipe-detail-overview__myrecipe-button">
          <DpMyrecipeButton
            :id="proRecipe.id"
            :state="isBookmarked"
            @switch-myrecipe-button="onSwitchMyrecipeButton" />
        </div>

        <div class="dp-pro-recipe-detail-overview-head">
          <p
            v-if="proRecipe.appeal_word"
            class="dp-pro-recipe-detail-overview-head__subtitle">
            {{ proRecipe.appeal_word }}
          </p>

          <h2
            v-if="proRecipe.name"
            class="dp-pro-recipe-detail-overview-head__title">
            {{ proRecipe.name }}
          </h2>

          <div class="dp-pro-recipe-detail-overview-head__bottom">
            <dl class="dp-pro-recipe-detail-overview-time">
              <template v-if="proRecipe.required_time">
                <dt>
                  <Icon name="timerR" color="dpBlack01" />
                  <span class="dp-pro-recipe-detail-overview-time__label">
                    {{ WORDS.DRIP_POD_PRO_RECIPE_DETAIL.REQUIRED_TIME }}
                  </span>
                </dt>
                <dd>{{ proRecipe.required_time }}</dd>
              </template>
            </dl>

            <div class="dp-pro-recipe-detail-overview-button">
              <DpButton type="button" size="sm" @click="onClickSetBody">
                <template v-slot:iconPrepend>
                  <Icon name="dpPlus" width="16" height="16" />
                </template>
                <span class="dp-pro-recipe-detail-overview-button__label">{{
                  WORDS.DRIP_POD_PRO_RECIPE_DETAIL.SET_BODY
                }}</span>
              </DpButton>
            </div>
          </div>
        </div>

        <p
          v-if="proRecipe.description"
          class="dp-pro-recipe-detail-overview-description"
          v-html="proRecipe.description" />
      </div>

      <div
        v-if="proRecipe.capsule && isPublishPeriod(proRecipe.capsule)"
        class="dp-pro-recipe-detail-capsule">
        <h3 class="dp-pro-recipe-detail-capsule__title">
          <Icon name="capsuleR" color="dpBlack01" :width="15" :height="15" />
          {{ WORDS.DRIP_POD_PRO_RECIPE_DETAIL.USE_CAPSULE }}
        </h3>

        <div class="dp-pro-recipe-detail-capsule-content">
          <div class="dp-pro-recipe-detail-capsule-content__thumbnail">
            <img :src="proRecipe.capsule.image_url" />
          </div>

          <div>
            <h3
              v-if="proRecipe.capsule.name"
              class="dp-pro-recipe-detail-capsule-content__title">
              {{ proRecipe.capsule.name }}
            </h3>
            <p
              v-if="proRecipe.capsule.description"
              class="dp-pro-recipe-detail-capsule-content__description">
              {{ proRecipe.capsule.description }}
            </p>
            <div class="dp-pro-recipe-detail-capsule-content__button">
              <DpTextButton
                v-if="capsuleEcUrl"
                :href="capsuleEcUrl"
                size="sm"
                align="left"
                fluid>
                <template v-slot:iconAppend>
                  <Icon name="dpArrowRightB" color="dpBlack01" />
                </template>
                {{ WORDS.DRIP_POD_PRO_RECIPE.STORE_LINK_CAPSULE }}
              </DpTextButton>
            </div>
          </div>
        </div>
      </div>

      <DpSection
        v-if="proRecipe.recipe && proRecipe.recipe.length > 0"
        :title-en="WORDS.DRIP_POD_PRO_RECIPE.RECIPE">
        <DpSectionInner>
          <DpProRecipeMaterialList :contents="proRecipe.recipe" />
        </DpSectionInner>
      </DpSection>

      <DpSection
        v-if="
          proRecipe.process &&
          proRecipe.process.length > 0 &&
          proRecipe.process[0]
        "
        :title-en="WORDS.DRIP_POD_PRO_RECIPE.STEPS">
        <DpSectionInner>
          <DpProRecipeStepList :contents="proRecipe.process" />
        </DpSectionInner>
      </DpSection>

      <DpSection
        v-if="proRecipe.capsule && isPublishPeriod(proRecipe.capsule)"
        :title-en="WORDS.DRIP_POD_PRO_RECIPE.TASTE">
        <DpSectionInner>
          <DpProRecipeTaste :contents="proRecipe" />
        </DpSectionInner>

        <DpSectionInner
          v-if="
            proRecipe.barista &&
            isPublishPeriod(proRecipe.barista) &&
            proInformation
          ">
          <DpProRecipeBalloon
            v-if="proRecipe.message"
            :title="WORDS.DRIP_POD_PRO_RECIPE.POINT"
            :text="proRecipe.message" />

          <DpProRecipeProInformation :contents="proInformation" />
        </DpSectionInner>
      </DpSection>

      <div class="dp-pro-recipe-detail-capsule-drip-button">
        <DpButton size="lg" style-type="primary" fluid @click="startBrew">
          {{ WORDS.DRIP_POD_PRO_RECIPE_DETAIL.DRIP }}
        </DpButton>
      </div>
    </div>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { SystemDialogMessage } from '@/constants/enums';
import {
  defineComponent,
  ref,
  computed,
  watch,
  inject,
  onMounted
} from '@vue/composition-api';
import { usePublishable } from '@/composables/usePublishable';
import { useNativeConnection } from '@/composables/useNativeConnection';
const { setBrewParameters, setFavoriteRecipeParameters } =
  useNativeConnection();

export default defineComponent({
  components: {
    DpPageLoading: () => import('@/components/dripPod/core/PageLoading.vue'),
    DpSection: () => import('@/components/dripPod/core/Section.vue'),
    DpSectionInner: () => import('@/components/dripPod/core/SectionInner.vue'),
    DpProRecipeDetailCarousel: () =>
      import('@/components/dripPod/proRecipeDetail/Carousel.vue'),

    DpProRecipeDetailCarouselCard: () =>
      import('@/components/dripPod/proRecipeDetail/CarouselCard.vue'),

    DpMyrecipeButton: () =>
      import('@/components/dripPod/core/MyrecipeButton.vue'),

    DpProRecipeMaterialList: () =>
      import('@/components/dripPod/proRecipe/MaterialList.vue'),

    DpProRecipeStepList: () =>
      import('@/components/dripPod/proRecipe/StepList.vue'),

    DpProRecipeBalloon: () =>
      import('@/components/dripPod/proRecipe/Balloon.vue'),

    DpProRecipeProInformation: () =>
      import('@/components/dripPod/proRecipe/ProInformation.vue'),

    DpProRecipeTaste: () => import('@/components/dripPod/proRecipe/Taste.vue'),
    DpTextButton: () => import('@/components/dripPod/core/TextButton.vue'),
    DpButton: () => import('@/components/dripPod/core/Button.vue')
  },

  beforeRouteEnter(to, from, next) {
    next(() => {
      const isBackFromBrewingPage = !!window.localStorage.getItem(
        'BackFromBrewingPage'
      );

      // 「What is TASTE MAP?」からの遷移時は何もしない
      if (from.name === 'DripPodProRecipeTasteMapOverview') return;

      /**
       * 「抽出中」画面からの遷移時、戻るボタンの遷移先をプロレシピ一覧に設定
       * それ以外の場合は遷移元のパスをローカルストレージに保存
       */
      if (isBackFromBrewingPage) {
        window.localStorage.setItem(
          'BackFromProRecipeDetail',
          '/drip-pod/pro-recipe/'
        );
      } else {
        if (!from.path) return;
        window.localStorage.setItem('BackFromProRecipeDetail', from.path);
      }
    });
  },

  beforeRouteLeave(to, from, next) {
    next(() => {
      // 「What is TASTE MAP?」への遷移時は何もしない
      if (to?.name && to.name === 'DripPodProRecipeTasteMapOverview') return;

      window.localStorage.removeItem('BackFromProRecipeDetail');
    });
  },

  setup: (props, context) => {
    const { isPublishPeriod } = usePublishable();
    const setHeaderTitle = inject('setHeaderTitle');
    const setHeaderBackTo = inject('setHeaderBackTo');
    const proRecipe = ref(undefined);
    const proInformation = ref(undefined);
    const loadedContentImgCount = ref(0);

    const proRecipeTitle = computed(() => proRecipe.value?.name || '');
    const proRecipeImage = computed(() => proRecipe.value?.image_url || '');
    const isBookmarked = ref(undefined);

    const proRecipeAdditionalImages = computed(() =>
      proRecipe.value.additional_images &&
      proRecipe.value.additional_images.length
        ? proRecipe.value?.additional_images.map((item) => item.image_url)
        : []
    );

    // NOTE: 抽出パラメータ
    const proRecipeBrewParameters = computed(() => {
      return {
        pre_value: proRecipe.value?.extract_parameter?.pre_value,
        pause_time: proRecipe.value?.extract_parameter?.pre_time,
        front_temperature:
          proRecipe.value?.extract_parameter?.front_temperature,
        front_value: proRecipe.value?.extract_parameter?.front_value,
        front_pump_pwm: proRecipe.value?.extract_parameter?.front_pump_pwm,
        back_temperature: proRecipe.value?.extract_parameter?.back_temperature,
        back_value: proRecipe.value?.extract_parameter?.back_value,
        back_pump_pwm: proRecipe.value?.extract_parameter?.back_pump_pwm,
        all_value: proRecipe.value?.extract_parameter?.all_value
      };
    });

    // NOTE: 抽出中のステップごとのメッセージ
    const proRecipeMessageParameters = computed(() => {
      const values = {
        step_message_extract1: proRecipe.value?.step_message?.extract1,
        step_message_extract2: proRecipe.value?.step_message?.extract2,
        step_message_steaming: proRecipe.value?.step_message?.steaming
      };

      return Object.keys(values).reduce((acc, key) => {
        if (values[key]) acc[key] = values[key];
        return acc;
      }, {});
    });

    // NOTE: 「このレシピでドリップする」選択時に渡すパラメータ
    const dripRecipeParameters = computed(() => {
      return {
        pro_recipe_id: proRecipeId.value,
        ...proRecipeMessageParameters.value,
        is_bookmarked: isBookmarked.value,
        ...proRecipeBrewParameters.value
      };
    });

    // NOTE: 本体登録時に渡すパラメータ
    const favoriteRecipeParameters = computed(() => {
      return {
        pro_recipe_id: proRecipeId.value,
        ...proRecipeBrewParameters.value
      };
    });

    const sleep = (ms = 1000) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const images = computed(() => {
      if (!proRecipe.value) return;
      return [proRecipeImage.value, ...proRecipeAdditionalImages.value].filter(
        (item) => !!item
      );
    });

    const proRecipeId = computed(
      () => Number(context.root.$route.params.proRecipeId) || ''
    );

    const isLoading = computed(
      () => loadedContentImgCount.value !== images.value?.length
    );

    const onContentImgLoaded = async () => {
      await sleep();
      loadedContentImgCount.value++;
    };

    /* eslint-disable indent */
    const capsuleEcUrl = computed(() =>
      proRecipe.value?.capsule?.link_url
        ? context.root.$customUrlScheme.page(
            'ec',
            `url=${proRecipe.value?.capsule.link_url}`
          )
        : false
    );
    /* eslint-enable */

    /**
     * 抽出開始の処理
     * 1. Nativeへ抽出のパラメータを返す
     * 2. 抽出開始のダイアログを表示する
     */
    const startBrew = async () => {
      if (!dripRecipeParameters.value) return;

      await setBrewParameters(dripRecipeParameters.value);
    };

    const moveBrewingPage = () => {
      if (!proRecipe.value?.id) return;
      context.root.$router.push({
        name: 'DripPodProRecipeBrewing',
        params: {
          proRecipeId: proRecipe.value?.id ? proRecipe.value.id : ''
        }
      });
    };

    const postState = async () => {
      if (!proRecipeId.value) return;
      const params = {
        recipe_id: proRecipeId.value
      };
      try {
        await context.root.$repositories('dpBrewHistory').create(params);
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      }
    };

    const setBrewStatus = async (status) => {
      if (!status) return;
      await postState();
      if (status?.is_started) moveBrewingPage();
    };

    const onClickSetBody = () => {
      setFavoriteRecipeParameters(favoriteRecipeParameters.value);
    };

    const fetchRecipeData = async () => {
      try {
        context.root.$_loading_start();
        const { data } = await context.root
          .$repositories('dpProRecipe')
          .getById(proRecipeId.value);

        proRecipe.value = data;
        proInformation.value = data?.barista;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      }
    };

    // NOTE: ブックマークを切り替えた時の処理
    const onSwitchMyrecipeButton = (status) => {
      isBookmarked.value = status;
    };

    watch(proRecipe, () => {
      if (proRecipe.value) {
        setHeaderTitle(proRecipeTitle.value);
        isBookmarked.value = !!proRecipe.value?.bookmark;
      }
    });

    watch(isLoading, () => {
      if (!isLoading.value) context.root.$_loading_stop();
      else context.root.$_loading_start();
    });

    // NOTE: ネイティブ側からインスタンスにアクセスするためにwindowにバインドしておく
    window.view = { setBrewStatus };

    fetchRecipeData();

    onMounted(async () => {
      const backToPath = window.localStorage.getItem('BackFromProRecipeDetail');
      if (!backToPath) return;

      setHeaderBackTo(window.localStorage.getItem('BackFromProRecipeDetail'));
    });

    return {
      WORDS,
      proRecipe,
      proRecipeId,
      images,
      proInformation,
      capsuleEcUrl,
      isPublishPeriod,
      isBookmarked,
      isLoading,
      onContentImgLoaded,
      startBrew,
      onClickSetBody,
      onSwitchMyrecipeButton
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-pro-recipe-detail {
  @include mixin.dpDefaultText;

  padding-bottom: 100px;
}

.dp-pro-recipe-detail-overview {
  position: relative;
  margin-bottom: 32px;
  padding: 0 16px;

  &__myrecipe-button {
    position: absolute;
    top: -54px;
    right: 0;
  }
}

.dp-pro-recipe-detail-overview-head {
  margin-bottom: 16px;

  &__subtitle {
    margin-bottom: 8px;
    color: variables.$dpGreyAa;
    font-size: 12px;
    line-height: 1.4;
  }

  &__title {
    margin-bottom: 16px;
    font-size: 22px;
    line-height: 1.25;
    font-weight: bold;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.dp-pro-recipe-detail-overview-time {
  display: grid;
  grid: auto-flow / 1fr 1fr;
  gap: 8px;
  place-items: center start;
  margin-bottom: 0;
  font-size: 14px;

  > dt {
    display: grid;
    gap: 8px;
    grid: auto-flow / 16px 1fr;
    place-items: center;
    margin-bottom: 0;
    font-weight: bold;
  }

  .c-icon {
    margin-bottom: 2px;
  }
}

.dp-pro-recipe-detail-overview-button {
  display: grid;
  place-items: center end;

  &__label {
    padding-top: 1px;
    line-height: 1.4;
  }
}

.dp-pro-recipe-detail-overview-description {
  font-size: 16px;
  line-height: 1.6;
  white-space: pre-wrap;
}

.dp-pro-recipe-detail-capsule {
  margin-bottom: 32px;
  padding: 0 16px;

  &__title {
    display: grid;
    grid: auto-flow / 15px 1fr;
    place-items: center start;
    gap: 8px;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.3;
    font-weight: bold;
  }

  > * {
    > * {
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.dp-pro-recipe-detail-capsule-content {
  display: grid;
  grid: auto-flow / 24% 1fr;
  place-items: center;
  gap: 16px;
  padding: 16px;
  background-color: variables.$dpWhite01;
  border: 1px solid variables.$dpGreyCc;

  &__thumbnail {
    @include mixin.imageAspectRatio(160, 160);

    img {
      object-fit: contain;
    }
  }

  &__title {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: bold;
  }

  &__description {
    margin-bottom: 0;
    color: variables.$dpGrey66;
    font-size: 12px;
  }

  &__button {
    > * {
      padding-bottom: 0 !important;
    }
  }
}

.dp-pro-recipe-detail-capsule-drip-button {
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 16px;
  width: 100%;
  background-color: rgba($color: #fff, $alpha: 90%);
  filter: drop-shadow(0 -2px 8px rgba(0, 0, 0, 20%));
}
</style>
